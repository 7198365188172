import React from "react";

import CheckCircle from "@heroicons/react/24/solid/CheckCircleIcon";


const NotificationModal = ({ show }) => {
  return (
    <div style={{
      position: "fixed",
      display: show ? "flex" : "none",
      alignItems: "center",
      justifyContent: "center",
      [`-webkit-animation`]: show ? "fadeinout 1s linear forwards" : "none",
      animation: show ? "fadeinout 3s linear forwards" : "none",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    }}>
      <div style={{
        width: "30%",
        height: "30%",
        borderRadius: "10px",
        display: show ? "flex" : "none",
        [`-webkit-animation`]: show ? "fadeinout 1s linear forwards" : "none",
        animation: show ? "fadeinout 3s linear forwards" : "none",
        overflow: "hidden",
        backgroundColor: "white",
        flexDirection: "column",
      }}>
        <div style={{
          width: "100%",
          flexGrow: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <CheckCircle style={{
            width: "8rem",
            height: "8rem",
            color: "green",
          }} />
        </div>
        <div style={{
          width: "100%",
          flexGrow: "1",
          color: "black",
          display: "flex",
          alignItems: "start",
          justifyContent: "center",
        }}>
          <h1 style={{
            fontSize: "2rem",
          }}>Success</h1>
        </div>
      </div>
    </div >
  );
};


export default NotificationModal;