import styled from "styled-components";
import { FlexibleDiv } from "../flexibleDiv/flexibleDiv.component";

export const ToastMessageContainer = styled(FlexibleDiv)`
  background: aquamarine;
  min-width: 30%;
  width: auto;
  padding: 0.7rem 4rem;
  justify-content: center;
  border-radius: 5px;
  position: absolute;
  /* top: 0;
  right: 0; */
  display: ${({ activate }) => (activate ? "flex" : "none")};
  -webkit-animation: ${({ activate }) =>
    activate ? "fadeinout 1s linear forwards" : "none"};
  animation: ${({ activate }) =>
    activate ? "fadeinout 3s linear forwards" : "none"};

  @-webkit-keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    70% {
      opacity: 1;
    }
  }

  @keyframes fadeinout {
    0%,
    100% {
      opacity: 0;
    }
    30% {
      opacity: 0.5;
    }
    70% {
      opacity: 1;
    }
  }
  @media (max-width: 800px) {
    width:90%;
  }
`;

export const Text = styled.p`
  color: blue;
  font-weight: 700;
  font-size: 1.3rem;
  @media (max-width: 800px) {
    font-size: 1.1rem;
  }
`;
// export const ToastMessageContainer = styled(FlexibleDiv)``;
// export const ToastMessageContainer = styled(FlexibleDiv)``;
